import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import headers from 'config/header';
import { http, useDefault } from 'hooks';
// import ELearningProjects from 'components/dashboards/default/ELearningProjects';
import { useEffectAsync } from 'helpers/utils';
import BackDrop from 'components/templates/BackDrop';
import ProjectStatistics from 'components/dashboards/project-management/project-statistics/ProjectStatistics';
import LeadsWidgets from 'components/dashboards/futura/LeadsWidgets';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import { EModal } from 'components/templates/Modal';
import TableModal from 'components/dashboards/futura/TableModal';
import TopLeads from 'components/dashboards/default/TopLeads';
import MostLeads from 'components/dashboards/most-leads/MostLeads';
import dayjs from 'helpers/dayjs';
// import { EModal } from 'components/templates/Modal';

// import ToalLeads from 'components/dashboards/default/ToalLeads';
// import { topPagesTableData } from 'data/dashboard/analytics';
// import TopPages from 'components/dashboards/analytics/top-pages/TopPages';
// import { totalSales } from 'data/dashboard/default';
export const topProducts = [
  ['product', '2019'],
  ['Boots4', 43],
  ['Reign Pro', 83]
];

const DashboardELearning = () => {
  const { loadBotton, setLoadBotton } = useDefault();
  const [general, setGeneral] = useState([]);
  const [prices, setPrices] = useState([]);
  const [courses, setCourses] = useState([]);
  const [leads, setLeads] = useState([]);
  const [allStudents, setallStudents] = useState([]);
  const { emodal, handleEModal, list } = useDefault();
  const [title, setTitle] = useState('');
  const [columns, setColumns] = useState([
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: 'lastname',
      Header: 'Apellidos'
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    },
    {
      accessor: 'courseName',
      Header: 'Curso'
    },
    {
      accessor: 'register',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { register } = rowData.row.original;
        return dayjs(register, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD');
      }
    }
  ]);
  useEffectAsync(async () => {
    const AllCourses = async () => {
      setLoadBotton(true);
      try {
        const { data } = await http(
          'dashboard_course/count_course_mark',
          headers()
        );
        setGeneral(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadBotton(false);
      }
    };
    const PricesCourses = async () => {
      setLoadBotton(true);
      try {
        const { data } = await http(
          'dashboard_course/price_course_mark',
          headers()
        );
        setPrices(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadBotton(false);
      }
    };

    const GetUserCourse = () => {
      list('dashboard_course/list_user_course').then(data => {
        data && setCourses(data);
      });
    };
    const TotalLeads = () => {
      list('dashboard_course/total_leads').then(data => {
        data && setLeads(data);
      });
    };
    GetUserCourse();
    AllCourses();
    PricesCourses();
    TotalLeads();
  }, []);
  if (general === undefined || prices === undefined || leads === undefined)
    return <BackDrop param={' '} />;
  if (
    !loadBotton &&
    general?.basic_cores?.length > 0 &&
    general?.marks?.length > 0 &&
    general?.levels?.length > 0 &&
    prices?.basic_cores?.length > 0 &&
    prices?.marks?.length > 0 &&
    leads?.marks?.length > 0 &&
    leads?.levels?.length > 0 &&
    prices?.levels?.length > 0
  )
    return (
      <>
        <Row className="g-3 mb-3">
          <Col xxl={4}>
            <MostLeads
              title={`Total de Cursos Según Marca`}
              total={general?.count}
              projectsTable={general.marks}
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
            {/* <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {' '}
              <ELearningProjects
                data={general.marks}
                title={`Total de Cursos ${general?.count} Según Marca`}
              />
            </div> */}
          </Col>
          <Col xxl={4}>
            <MostLeads
              title={`Total de Cursos Según Nivel`}
              total={general?.count}
              projectsTable={general.levels}
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
            {/* <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <ELearningProjects
                data={general.levels}
                title={`Total de Cursos ${general?.count} Según Nivel`}
              />
            </div> */}
          </Col>

          <Col xxl={4}>
            <MostLeads
              title={`Total de Cursos Según Núcleo`}
              projectsTable={general.basic_cores}
              total={general?.count}
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
            {/* <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <ELearningProjects
                data={general.basic_cores}
                title={`Total de Cursos ${general?.count} Según Núcleo`}
              />
            </div> */}
          </Col>
        </Row>
        {/* <Row className="g-3 mb-3">
          <Col>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {' '}
              <ELearningProjects
                data={general.marks}
                title={`Total de Cursos ${general?.count} Según Marca`}
              />
            </div>
          </Col>
          <Col>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <ELearningProjects
                data={general.levels}
                title={`Total de Cursos ${general?.count} Según Nivel`}
              />
            </div>
          </Col>

          <Col>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <ELearningProjects
                data={general.basic_cores}
                title={`Total de Cursos ${general?.count} Según Núcleo`}
              />
            </div>
          </Col>
        </Row> */}
        <Row className="g-3 mb-3">
          <Col>
            <ProjectStatistics
              projectsTable={prices.marks}
              title="Precios según Marca"
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
          </Col>
          <Col>
            <ProjectStatistics
              projectsTable={prices.levels}
              title="Precios según Nivel"
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
          </Col>
          <Col>
            <ProjectStatistics
              projectsTable={prices.basic_cores}
              title="Precios según Núcleo"
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col md={3} xxl={3}>
            <LeadsWidgets
              data={courses?.courses}
              items={courses?.items}
              title="Cursos"
              setTitle={setTitle}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              background={bg1}
            />
          </Col>
        </Row>
        <EModal
          form_={
            <TableModal items={allStudents} title={title} columns={columns} />
          }
          title={''}
          modalSize={'lg'}
          modal={emodal}
          handleModal={handleEModal}
        />
        <Row className="g-3 mb-3">
          {' '}
          <Col lg={6}>
            <TopLeads data={leads.marks} title={'Total Leads según Marca'} />
            {/* <ToalLeads data={totalSales} title={'Total Leads según Marca'} /> */}
          </Col>
          <Col lg={6}>
            <TopLeads data={leads.levels} title={'Total Leads según Nivel'} />
            {/* <ToalLeads data={totalSales} title={'Total Leads según Nivel'} /> */}
          </Col>
        </Row>
        {/* <TopPages tableData={topPagesTableData} perPage={6} /> */}
      </>
    );
};

export default DashboardELearning;
